/* eslint-disable eqeqeq */
import axios from "axios";
import { toaster } from "./custom/Toast";

export const API_URL =
  // "https://newzealanddairy-hrx-api.salesx.xyz/api/v1" ||
  // "http://localhost:5050/api/v1" ||
  process.env.REACT_APP_API;

export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const axiosApi = axios.create({
  baseURL: "https://nzdp-staging-api.encodebd.com/api/v1",
});

//  axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("GLOBAL_ERROR", error.response);
    if (
      (error.response?.status === 401 &&
        error.response?.data?.message ===
          "The user belonging to this token does no longer exist.") ||
      (error.response?.status === 401 &&
        error.response?.data?.message ===
          "User recently changed password! Please log in again.")
    ) {
      // console.log("Hello");
      toaster("error", error.response?.data?.message);
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config) {
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
